import React from "react";
import { DataTable, Divider, HelperText, Portal, RadioButton, Text } from 'react-native-paper';
import {Dashboard, DisplayText, InputDropDown, InputImage, InputNumber, InputText, PrimaryButton} from "../components";
import { Colors, Layout, Typography } from "../styles";
import { Image, ScrollView, StyleSheet, View } from "react-native";
import { BaseURL, setMediaQuery } from "../utils/Constants";
import { formatDateLong, formatMoney, formatMoneyShort } from "../utils/Helpers";
import Swal from "sweetalert2";

export default function Booking() {
    const mediaQuery = setMediaQuery();
    const [isLoading, setIsLoading] = React.useState(true);
    const [dealers, setDealers] = React.useState([]);
    const [motors, setMotors] = React.useState([]);
    const [types, setTypes] = React.useState([]);
    const [colorSelections, setColorSelections] = React.useState([]);
    const [colors, setColors] = React.useState([]);
    const [creditHeaders, setCreditHeaders] = React.useState([]);
    const [creditLabels, setCreditLabels] = React.useState([]);
    const [creditValues, setCreditValues] = React.useState([]);
    const [selectedType, setSelectedType] = React.useState(undefined);
    const [selectedMotor, setSelectedMotor] = React.useState({});
    const [selectedColorFile, setSelectedColorFile] = React.useState(undefined);
    const [selectedCreditLabelsIndex, setSelectedCreditLabelsIndex] = React.useState(-1);
    const [selectedCreditHeadersIndex, setSelectedCreditHeadersIndex] = React.useState(-1);

    const [rightView, setRightView] = React.useState();
    const [leftView, setLeftView] = React.useState();
    let isLeftScrolling = false, isRightScrolling = false;

    const [booking, setBooking] = React.useState({
        dealer_id: { value: 0, error: '' },
        motor_id: { value: 0, error: '' },
        motor_color_id: { value: 0, error: '' },
        alamat: { value: '', error: '' },
        no_hp_1: { value: '', error: '' },
        no_hp_2: { value: '', error: '' },
        email: { value: '', error: '' },
        dp: { value: '', error: '' },
        angsuran: { value: '', error: '' },
        top: { value: '', error: '' },
        otr: {value: '', error: ''},
        nama_stnk: {value: '', error: ''},
    });

    const [ktp, setKtp] = React.useState({name: '', kode: '', value: '', file: undefined, img: '', error: ''});
    const [kk, setKk] = React.useState({name: '', kode: '', value: '', file: undefined, img: '', error: ''});
    const [ktpPenjamin, setKtpPenjamin] = React.useState({name: '', kode: '', value: '', file: undefined, img: '', error: ''});
    const [kkPenjamin, setKkPenjamin] = React.useState({name: '', kode: '', value: '', file: undefined, img: '', error: ''});

    const fetchDealers = () => {
        setIsLoading(true);
        fetch(`${BaseURL}/dealers/selection`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            const responseJSON = await response.json();
            if (responseJSON.success) setDealers(responseJSON.data);
        }).finally(() => {
            setIsLoading(false);
        });
    };
    const fetchMotors = () => {
        setIsLoading(true);
        fetch(`${BaseURL}/motors`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            const responseJSON = await response.json();
            if (responseJSON.success) setMotors(responseJSON.data);
        }).finally(() => {
            setIsLoading(false);
        });
    };
    const fetchTypes = () => {
        setIsLoading(true);
        fetch(`${BaseURL}/motors/types`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            const responseJSON = await response.json();
            if (!responseJSON.success) return;
            setTypes(responseJSON.data.map((item) => ({ value: item.id, label: item.name })));
            setSelectedType(1);
        }).finally(() => {
            setIsLoading(false);
        });
    };
    const fetchColors = (id) => {
        setIsLoading(true);
        fetch(`${BaseURL}/motors/${id}/colors`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            const responseJSON = await response.json();
            if (!responseJSON.success) return;
            const mappedData = responseJSON.colors.map(item => ({
                value: item.id,
                label: item.name
            }));
            setColors(responseJSON.colors);
            setColorSelections(mappedData);
            setBookingValue({ value: 1 }, 'motor_color_id');
        }).finally(() => {
            setIsLoading(false);
        });
    };
    const fetchCreditTable = (id) => {
        setIsLoading(true);
        fetch(`${BaseURL}/motors/${id}/credit`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        }).then(async response => {
            const responseJSON = await response.json();
            if (!responseJSON.success) return;
            setCreditHeaders(responseJSON.data.map(item => ({ dp: item.down_payment })));
            setCreditLabels(responseJSON.label.map(item => item.toString()));
            setCreditValues(responseJSON.detail);
        }).finally(() => {
            setIsLoading(false);
        });
    };
    // endregion
    const setBookingValue = (params, key) => {
        const item = booking[key];
        if (!item) return;
        if (params.value || params.value === '') item.value = params.value;
        if (params.error || params.error === '') item.error = params.error;
        setBooking(prevState => ({ ...prevState, [key]: item }));
    };
    const setMotor = (id) => {
        if (id === booking.motor_id.value) return;
        setBookingValue({ value: id }, 'motor_id');
        fetchColors(id);
        fetchCreditTable(id);
    };
    const setColor = (id) => {
        const color = colors.find(item => item.id === id);
        setBookingValue({ value: id }, 'motor_color_id');
        setSelectedColorFile(color.file);
    }
    const filteredMotors = () => {
        const data = motors.filter(item => item.type_id === selectedType);
        return data.map(item => ({ value: item.id, label: item.name }));
    };

    // region useEffect
    React.useEffect(() => {
        fetchDealers();
        fetchTypes();
        fetchMotors();
    }, []);

    React.useEffect(() => {
        const unit = motors.find(item => item.id === booking.motor_id.value);
        if (!unit) return;
        setSelectedMotor(unit);
        setBookingValue({ value: unit.price }, 'otr');
    }, [booking.motor_id.value]);

    React.useEffect(() => {
        if (selectedCreditHeadersIndex + selectedCreditLabelsIndex < 0) return;
        const credit = creditValues[selectedCreditHeadersIndex][selectedCreditLabelsIndex];
        const tenor = creditLabels[selectedCreditLabelsIndex];
        if (credit) setBookingValue({ value: credit }, 'angsuran');
        if (tenor) setBookingValue({ value: tenor }, 'top');
    }, [selectedCreditHeadersIndex, selectedCreditLabelsIndex]);
    // end region

    const submit = () => {
        setIsLoading(true);
        const formData = new FormData();
        formData.append('dealer_id', booking.dealer_id.value);
        formData.append('motor_id', booking.motor_id.value);
        formData.append('motor_color_id', booking.motor_color_id.value);
        formData.append('ktp_file', ktp.file);
        formData.append('kk_file', kk.file);
        formData.append('dp', booking.dp.value);
        formData.append('top', booking.top.value);
        formData.append('angsuran', booking.angsuran.value);
        formData.append('alamat', booking.alamat.value);
        formData.append('otr', booking.otr.value);
        formData.append('email', booking.email.value);
        formData.append('no_hp_1', booking.no_hp_1.value);
        formData.append('no_hp_2', booking.no_hp_2.value);
        formData.append('nama_stnk_bpkb', booking.nama_stnk.value);

        if(ktpPenjamin.file){
            formData.append('ktpPenjaminFile', ktpPenjamin.file);
        }
        if(kkPenjamin.file){
            formData.append('kkPenjaminFile', kkPenjamin.file);
        }

        fetch(`${BaseURL}/bookings/create`, {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(async response => {
            const responseJSON = await response.json();
            if (!responseJSON.success) {
                for (const [key, value] of Object.entries(responseJSON.message)) {
                    setBookingValue({ error: responseJSON.message[key] }, key)
                }
                setKk(prevState => ({ ...prevState, error: responseJSON.message.kk_file }));
                setKtp(prevState => ({ ...prevState, error: responseJSON.message.ktp_file }));

                await Swal.fire({
                    title: 'Gagal input credit motor',
                    icon: 'error',
                    confirmButtonText: 'Ok'
                });
            } else {
                await Swal.fire({
                    title: 'Berhasil Booking Motor',
                    icon: 'success',
                    confirmButtonText: 'Ok'
                });
            }
        }).finally(() => {
            setIsLoading(false);
        });
    }
    return (
        <View style={{ flex: 1, padding: 10, width: mediaQuery.tablet ? '100%' : '80%'}}>
            <Text style={Typography.h1}>Booking Motor</Text>
            <Divider style={Layout.divider}/>
            <View style={{flexDirection: 'row', justifyContent: 'space-between', marginVertical: 10}}>
                <Text style={{ color: Colors.gray,  fontSize: 12 }}>
                    Tanggal
                </Text>
                <Text style={{ color: Colors.black,  fontSize: 12, fontWeight: '500' }}>
                    {formatDateLong()}
                </Text>
            </View>
            <InputDropDown
                label={"Pilih Dealer"}
                data={dealers}
                value={booking.dealer_id.value}
                error={booking.dealer_id.error}
                onSelect={value => setBookingValue({ value: value }, 'dealer_id')}

            />
            <Text style={Typography.h2}>Data Pemohon</Text>
            <Divider style={Layout.divider}/>
            <InputImage
                img={ktp.img}
                value={ktp.value}
                label="File KTP"
                onChange={e => {
                    const item = {
                        value: e.target.value,
                        file: e.target.files[0],
                        img: URL.createObjectURL(e.target.files[0])
                    };
                    setKtp(item);
                }}
            />
            {/*KTP*/}
            <InputImage
                img={kk.img}
                value={kk.value}
                label="File KK"
                onChange={e => {
                    const item = {
                        value: e.target.value,
                        file: e.target.files[0],
                        img: URL.createObjectURL(e.target.files[0])
                    };
                    setKk(item);
                }}
            />
            {/*KK*/}
            <InputText
                label={"Alamat"}
                value={booking.alamat.value}
                error={booking.alamat.error}
                onChangeText={value => { setBookingValue({ value: value }, 'alamat') }}
            />
            <InputText
                email
                label={"Email"}
                value={booking.email.value}
                error={booking.email.error}
                onChangeText={value => setBookingValue({ value: value }, 'email')}
            />
            <InputNumber
                label={"No hp 1"}
                value={booking.no_hp_1.value}
                error={booking.no_hp_1.error}
                setError={error => setBookingValue({ error: error }, 'no_hp_1')}
                onChangeText={value => setBookingValue({ value: value }, 'no_hp_1')}
            />
            <InputNumber
                label={"No hp 2"}
                value={booking.no_hp_2.value}
                error={booking.no_hp_2.error}
                setError={error => setBookingValue({ error: error }, 'no_hp_2')}
                onChangeText={value => setBookingValue({ value: value }, 'no_hp_2')}
            />
            <Text style={Typography.h2}>Data Penjamin</Text>
            <Divider style={Layout.divider}/>
            <InputImage
                img={ktpPenjamin.img}
                value={ktpPenjamin.value}
                label="File KTP"
                onChange={e => {
                    const item = {
                        value: e.target.value,
                        file: e.target.files[0],
                        img: URL.createObjectURL(e.target.files[0])
                    };
                    setKtpPenjamin(item);
                }}
            />
            {/*KTP*/}
            <InputImage
                img={kkPenjamin.img}
                value={kkPenjamin.value}
                label="File KK"
                onChange={e => {
                    const item = {
                        value: e.target.value,
                        file: e.target.files[0],
                        img: URL.createObjectURL(e.target.files[0])
                    };
                    setKkPenjamin(item);
                }}
            />
            {/*KK*/}
            <Text style={Typography.h2}>Pilih Motor</Text>
            <Divider style={Layout.divider}/>
            <InputDropDown
                label={"Pilih Tipe Unit"}
                data={types}
                value={selectedType}
                onSelect={setSelectedType}
            />
            <InputDropDown
                label={"Pilih Unit"}
                data={filteredMotors()}
                value={booking.motor_id.value}
                onSelect={setMotor}
            />
            <InputDropDown
                label={"Pilih Warna"}
                data={colorSelections}
                value={booking.motor_color_id.value}
                onSelect={setColor}
            />
            {selectedColorFile ?
                <Image source={{ uri: `${BaseURL}/${selectedColorFile}` }} style={styles.img}/>
                : <></>
            }
            <DisplayText
                inline
                title={"Kode Unit"}
                label={selectedMotor.kode}
                style={styles.displayText}
            />
            <DisplayText
                inline
                title={"OTR"}
                label={formatMoney(selectedMotor.price)}
                style={styles.displayText}
            />
            {/*endregion*/}
            {/*region Data Kredit*/}
            <Text style={Typography.h3}>Pilih Kredit</Text>
            <Divider style={Layout.divider}/>
            <InputText
                label={"Nama STNK dan BPKB"}
                value={booking.nama_stnk.value}
                error={booking.nama_stnk.error}
                onChangeText={value => setBookingValue({ value: value }, 'nama_stnk')}
            />
            {creditHeaders[0] && creditValues[0] && creditLabels[0] ?
                <View style={{ marginBottom: 10 }}>
                    <HelperText style={{ marginBottom: 8 }}>
                        * Klik kolom DP untuk memilih DP dan klik kolom angsuran untuk memilih angsuran
                    </HelperText>
                    <View style={{ flex: 1, flexDirection: 'row', maxHeight: 480 }}>
                        <DataTable style={{ flex: 1 }}>
                            <DataTable.Header style={Layout.tableHeader}>
                                <DataTable.Title>
                                    <Text style={Typography.tableTitle}>DP</Text>
                                </DataTable.Title>
                            </DataTable.Header>
                            <ScrollView
                                style={{ flex: 1 }}
                                showsVerticalScrollIndicator={false}
                                nestedScrollEnabled={true}
                                scrollEventThrottle={creditHeaders.length}
                                ref={scrollView => setLeftView(scrollView)}
                                onScroll={e => {
                                    if (!isLeftScrolling) {
                                        isRightScrolling = true
                                        rightView.scrollTo({ y: e.nativeEvent.contentOffset.y })
                                    }
                                    isLeftScrolling = false
                                    // rightView.scrollTo({ y: e.nativeEvent.contentOffset.y })
                                }}>
                                {creditHeaders.map((item, i) => (
                                    <DataTable.Row
                                        key={i}
                                        onPress={() => {
                                            setBookingValue({ value: item.dp }, 'dp');
                                            setSelectedCreditHeadersIndex(i);
                                        }}>
                                        <DataTable.Cell style={Layout.tableCell}>{formatMoneyShort(item.dp)}</DataTable.Cell>
                                    </DataTable.Row>
                                ))}
                            </ScrollView>
                        </DataTable>
                        <ScrollView horizontal style={{ flex: 1 }}>
                            <DataTable>
                                <DataTable.Header style={Layout.tableHeader}>
                                    {creditLabels.map((item, i) => (
                                        <DataTable.Title key={i} style={styles.cell}>
                                            <Text style={Typography.tableTitle}>{item} x</Text>
                                        </DataTable.Title>
                                    ))}
                                </DataTable.Header>
                                <ScrollView
                                    nestedScrollEnabled={true}
                                    showsVerticalScrollIndicator={false}
                                    scrollEventThrottle={creditValues.length}
                                    ref={scrollView => setRightView(scrollView)}
                                    onScroll={e => {
                                        if (!isRightScrolling) {
                                            isLeftScrolling = true;
                                            leftView.scrollTo({ y: e.nativeEvent.contentOffset.y })
                                        }
                                        isRightScrolling = false;
                                        // leftView.scrollTo({ y: e.nativeEvent.contentOffset.y })
                                    }}
                                >
                                    {creditValues.map((cols, i) => (
                                        <DataTable.Row
                                            key={i}
                                            style={[i !== selectedCreditHeadersIndex ? { backgroundColor: Colors.gray50 } : {}]}>
                                            {cols.map((item, j) => (
                                                <DataTable.Cell
                                                    key={j}
                                                    style={Layout.tableCell}
                                                    onPress={() => setSelectedCreditLabelsIndex(j)}>
                                                    <Text style={[
                                                        j === selectedCreditLabelsIndex && i === selectedCreditHeadersIndex ? {color: Colors.green} : {},
                                                        i !== selectedCreditHeadersIndex ? {color: Colors.gray} : {}
                                                    ]}>
                                                        {formatMoneyShort(item)}
                                                    </Text>
                                                </DataTable.Cell>
                                            ))}
                                        </DataTable.Row>
                                    ))}
                                </ScrollView>
                            </DataTable>
                        </ScrollView>
                    </View>
                </View> :
                <HelperText style={{ color: Colors.red, marginBottom: 16 }}>* Pilih motor dahulu untuk memilih kredit</HelperText>
            }
            <DisplayText
                inline
                title={"DP Gross"}
                label={formatMoney(booking.dp.value)}
                style={styles.displayText}
            />
            <DisplayText
                inline
                title={"Angsuran"}
                label={formatMoney(booking.angsuran.value)}
                style={styles.displayText}
            />
            <DisplayText
                inline
                title={"TOP"}
                label={`${booking.top.value} ${booking.top.value ? 'Bulan' : ''}`}
                style={styles.displayText}
            />
            <Divider style={Layout.divider}/>
            {/*endregion*/}
            <View style={{ paddingBottom: 16 }}>
                <PrimaryButton label={"Submit"} onPress={submit}/>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    dropdown: {
        paddingBottom: 4
    },
    displayText: {
        marginVertical: 10,
    },
    img: {
        resizeMode: 'contain',
        width: '100%',
        minHeight: 200,
        marginBottom: 10
    }
})