import { Image, Linking, StyleSheet, View, Text, TextInput } from "react-native";
import { Button, HelperText, TouchableRipple, IconButton } from "react-native-paper";
import { Colors, dimensions, Layout } from "../styles";
import SelectDropdown from "react-native-select-dropdown";
import React from "react";
import { BaseURL, setMediaQuery } from "../utils/Constants";
import { NoImage } from "../images";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import FontAwesomeIcon from "react-native-vector-icons/FontAwesome";

export { default as Dashboard } from '../pages/Dashboard';

export const InputImage = (props) => {
    return (
        <View style={[props.style, { marginBottom: 10 }]}>
            <Text>{props.label}</Text>
            <Image
                source={{ uri: props.img }}
                style={{
                    resizeMode: 'contain',
                    width: '100%',
                    minHeight: props.img ? 200 : 0,
                    marginBottom: 20
                }}/>
            <View style={{ marginTop: 5 }}>
                <input
                    type="file"
                    accept="image/*"
                    value={props.value}
                    onChange={props.onChange}
                />
            </View>
            <HelperText type="error" visible={props.error}>
                {props.error}
            </HelperText>
        </View>
    )
}

export const InputDropDown = (props) => {
    const [isFocused, setIsFocused] = React.useState(false);
    React.useEffect(() => {
        setIsFocused(!!props.value || !!props.focused);
    }, [props.value]);
    const data = props.data ?? [];
    const onSelect = (item) => {
        setIsFocused(true)
        props.onSelect(item.value);
    };
    const dismiss = () => {
        if (props.value) return;
        setIsFocused(false);
    };
    return (
        <View style={[{ marginBottom: 10 }, props.style]}>
            {isFocused ? (
                <HelperText style={{ color: Colors.gray, padding: 0 }}>
                    {props.label}
                </HelperText>
            ) : <View/>}
            <SelectDropdown
                defaultButtonText={props.label}
                data={data}
                disabled={!data || data.length <= 0}
                dropdownIconPosition={"right"}
                onSelect={onSelect}
                buttonTextAfterSelection={selectedItem => selectedItem.label}
                rowTextForSelection={item => item.label}
                buttonStyle={{
                    width: '100%',
                    backgroundColor: Colors.white,
                    paddingHorizontal: 10,
                    paddingVertical: 2,
                    borderColor: Colors.gray,
                    borderWidth: 1,
                    borderRadius: 5,
                    height: 40
                }}
                buttonTextStyle={{ textAlign: 'left', fontSize: 14 }}
                dropdownStyle={{ borderRadius: 10, maxHeight: dimensions.height / 2 }}
                rowStyle={{ padding: 10 }}
                renderDropdownIcon={isOpened => (
                    <MaterialCommunityIcons
                        name={isOpened ? "chevron-up" : "chevron-down"}
                        color={"#444"}
                        size={18}
                    />
                )}
            />
            {props.error ? (
                <HelperText type="error" visible={props.error}>
                    {props.error}
                </HelperText>
            ) : <View/>}
        </View>
    )
};

export const InputText = (props) => {
    const email = props.email ?? false;
    const [isFocused, setIsFocused] = React.useState(false);
    React.useEffect(() => {
        setIsFocused(!!props.value || !!props.focused);
    }, [props.value]);
    const focus = () => {
        setIsFocused(true)
    };
    const dismiss = () => {
        if (props.value) return;
        setIsFocused(false);
    };
    return (
        <View style={{ ...props.style, marginBottom: 10 }}>
            {isFocused ? (
                <HelperText style={{ color: Colors.gray, padding: 0 }}>
                    {props.label}
                </HelperText>
            ) : <View/>}
            <TextInput
                keyboardType={email ? 'email-address' : 'default'}
                placeholder={!isFocused ? props.label : ''}
                value={props.value}
                error={props.error}
                onChangeText={props.onChangeText}
                onFocus={focus}
                onEndEditing={dismiss}
                style={[Layout.input, {
                    marginBottom: props.error ? 5 : 0,
                    backgroundColor: Colors.white,
                }]}
            />
            {props.error ? (
                <HelperText type="error" visible={props.error}>
                    {props.error}
                </HelperText>
            ) : <View/>}
        </View>
    )
}
export const InputNumber = (props) => {
    const [isFocused, setIsFocused] = React.useState(false);
    React.useEffect(() => {
        setIsFocused(!!props.value || !!props.focused);
    }, [props.value]);
    const focus = () => setIsFocused(true);
    const dismiss = () => {
        if (props.value) return;
        setIsFocused(false);
    };
    const onChangeText = (text) => {
        const isNumeric = /^\d+$/.test(text);
        if (!isNumeric && text !== '') return props.setError("Input harus angka");
        props.onChangeText(text)
        props.setError("");
    }
    return (
        <View style={{ ...props.style, marginBottom: 10 }}>
            {isFocused ? (
                <HelperText style={{ ...props.labelStyle, color: Colors.gray, padding: 0 }}>
                    {props.label}
                </HelperText>
            ) : <></>}
            <TextInput
                keyboardType={'numeric'}
                placeholder={!isFocused ? props.label : ''}
                value={props.value}
                error={props.error}
                onChangeText={onChangeText}
                onFocus={focus}
                onEndEditing={dismiss}
                style={[
                    Layout.input, {
                        marginBottom: props.error ? 5 : 0,
                        backgroundColor: Colors.white,
                    }
                ]}/>
            {props.error ? (
                <HelperText type="error" visible={props.error}>
                    {props.error}
                </HelperText>
            ) : <></>}
        </View>
    )
}

export const DisplayText = (props) => {
    const { inline = false, numeric = false, style, titleStyle, labelStyle, label, title } = props;
    return (
        <View style={[{
            flex:1,
            flexWrap: 'wrap',
            paddingHorizontal: 5,
            flexDirection: inline ? 'row' : 'column',
            justifyContent: inline ? 'space-between' : 'flex-start'
        }, style]}>
            <Text style={[
                {color: Colors.gray, fontSize: 14, marginBottom: inline ? 0 : 8, flex: title ? 1 : 0},
                titleStyle
            ]}>
                {title}
            </Text>
            <Text style={[{
                color: Colors.black,
                fontSize: 14, fontWeight: '500', textAlign: numeric ? 'right' : 'left',
                flex: inline ? 2 : 1,
                flexWrap: 'wrap',
                alignSelf: 'baseline',
                marginLeft: inline ? 20 : 0
            }, labelStyle]}>
                {label}
            </Text>
        </View>
    )
}

export const PrimaryButton = (props) => {
    return (
        <Button
            mode="contained"
            icon={props.icon}
            contentStyle={{ backgroundColor: props.color ? props.color : Colors.red, padding: 5 }}
            labelStyle={{ fontWeight: 'bold', fontSize: 12, marginVertical: 5 }}
            style={[{ backgroundColor: props.color ? props.color : Colors.red, borderRadius: 5 }, props.style]}
            onPress={props.onPress}
        >
            {props.label}
        </Button>
    )
}

export const SecondaryButton = (props) => {
    return (
        <Button
            mode="outlined"
            icon={props.icon}
            contentStyle={{ borderColor: Colors.red, padding: 5 }}
            labelStyle={{ color: Colors.red, fontWeight: 'bold' }}
            style={props.style}
            onPress={props.onPress}
        >
            {props.label}
        </Button>
    )
}
export const ToggleButton = (props) => {
    const { status, onPress, label, contentStyle, style } = props;
    const mediaQuery = setMediaQuery();
    return (
        <Button
            mode="text"
            color={Colors.black}
            contentStyle={{
                borderBottomWidth: status ? 2 : 0,
                borderColor: Colors.black,
                margin: 0,
                paddingVertical: 8,
                ...contentStyle
            }}
            labelStyle={{
                fontSize: mediaQuery.desktop ? 14 : 10,
                fontWeight: '500',
                margin: 0
            }}
            style={{
                flex: 1,
                margin: 0,
                ...style
            }}
            onPress={onPress}
        >
            {label}
        </Button>
    )
}


export const SearchBar = props => {
    const { value, onChangeText, placeholder } = props;
    const mediaQuery = setMediaQuery();
    const styles = setStyles(mediaQuery);
    return (
        <View style={[styles.inputContainer, props.containerStyle]}>
            <TextInput
                placeholder={placeholder}
                style={styles.input}
                value={value}
                onChangeText={onChangeText}
            />
            <FontAwesomeIcon
                name="search"
                size={18}
                color={Colors.gray}
                style={{
                    paddingRight: 6,
                    paddingHorizontal: 6,
                    borderWidth: 0
                }}
            />
        </View>
    )
}

export const DownloadableImage = (props) => {
    const src = props.img ? { uri: `${BaseURL}/${props.img}` } : NoImage;
    const handleDownload = async () => {
        if (!props.img) return;
        await Linking.openURL(BaseURL + '/' + props.img + '/download')
    }
    return (
        <View style={{ ...props.style, width: '100%' }}>
            <Text
                style={{ fontSize: 24, textAlign: 'center', color: Colors.gray, marginVertical: 10, fontweight: 700 }}>
                {props.subLabel}
            </Text>
            <Image source={src} style={Layout.img}/>
            <TouchableRipple onPress={handleDownload}>
                <View style={{
                    flex: 1,
                    width: '100%',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <Text style={{ fontSize: 12, color: Colors.gray }}>
                        Download
                    </Text>
                    <IconButton
                        icon={"download"}
                        size={24}
                        color={Colors.gray}
                    />
                </View>
            </TouchableRipple>
        </View>
    )
}

const style = StyleSheet.create({
    inputContainer: {
        width: '100%',
        marginBottom: 10
    },
    textInput: {
        marginBottom: 5
    }
});


const setStyles = () => ({
    button: {
        backgroundColor: 'transparent',
        borderColor: Colors.gray,
        borderWidth: 2,
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingLeft: 16,
        paddingRight: 24
    },
    buttonTitle: {
        color: Colors.black,
        fontSize: 16,
        fontWeight: 500,
        marginLeft: 10
    },
    inputContainer: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        borderWidth: 2,
        borderColor: Colors.gray,
        borderRadius: 3,
        paddingVertical: 2,
        paddingHorizontal: 5,
        backgroundColor: Colors.white
    },
    input: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        minHeight: 48,
        paddingHorizontal: 6,
        minWidth: 0,
        outlineStyle: 'none'
    },
});