import { View } from "react-native-web";
import { ActivityIndicator, Text } from "react-native-paper";
import { useNavigate, useParams } from "react-router";
import FontAwesomeIcon from "react-native-vector-icons/FontAwesome";
import React from "react";
import { Image, Linking, StyleSheet, TouchableOpacity } from "react-native";
import { BaseURL, setMediaQuery } from "../utils/Constants";
import Feature from "./detail-pages/Feature";
import Specs from "./detail-pages/Specs";
import Pricelist from "./detail-pages/Pricelist";
import Colors from "../styles/Colors";
import { formatMoney } from "../utils/Helpers";
import DetailMenu from "./component/DetailMenu";
import { NoImage } from "../images";

const tabs = {
    'feature': 1,
    'specs': 2,
    'pricelist': 3
}
export default function Details() {
    const { id } = useParams();

    const mediaQuery = setMediaQuery();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = React.useState(true);
    const [motor, setMotor] = React.useState(null);
    const [colors, setColors] = React.useState([]);
    const [colorIndex, setColorIndex] = React.useState(0);
    const [selectedTab, setSelectedTab] = React.useState(1);

    const fetchMotor = () => {
        fetch(`${BaseURL}/motors/${id}`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            const responseJSON = await response.json();
            if (!responseJSON.success) return;
            setMotor(responseJSON.data);
            fetchColor(responseJSON.data.id);
        }).catch(error => {
            console.log(error);
        });
    }
    const fetchColor = (id) => {
        fetch(`${BaseURL}/motors/${id}/colors`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            const responseJSON = await response.json();
            if (!responseJSON.success) return;
            setColors(responseJSON.colors ?? []);
        }).catch(error => {
            console.log(error);
        });
    }

    React.useEffect(() => {
        fetchMotor();
        setIsLoading(false);
    }, [])

    const switchColor = (left = true) => {
        let index = colorIndex;
        if (left) {
            if (index <= 0) index = colors.length;
            index--;
        } else {
            index++;
            if (index > colors.length - 1) index = 0;
        }
        setColorIndex(index);
    }

    const downloadImage = async () => {
        if (!colors[colorIndex]) return;
        await Linking.openURL(BaseURL + '/' + colors[colorIndex].file + '/download')
    }

    const onShare = async () => {
        // const options = { url: BaseURL + '/' + colors[colorIndex].file + '/download' };
        // try {
        //     const res = await RNShare.open(options);
        //     console.log(JSON.stringify(res))
        // } catch (error) {
        //     alert(error.message);
        // }
    };

    const currentTab = () => {
        if (!motor) return <> </>
        if (selectedTab === tabs.feature) {
            return <Feature motor={motor}/>
        }
        if (selectedTab === tabs.specs) {
            return <Specs motor={motor}/>
        }
        if (selectedTab === tabs.pricelist) {
            return <Pricelist motor={motor}/>
        }
    }

    if (isLoading) return (
        <ActivityIndicator animating={true} color={Colors.red} size={'large'}/>
    )
    return (
        <View style={{
            flex: 1,
            paddingHorizontal: 10,
            width: '100%',
            maxWidth: 1024
        }}>
            <View style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: 10,
            }}>
                <TouchableOpacity onPress={() => switchColor()} style={styles.buttonArrow}>
                    <FontAwesomeIcon
                        name="chevron-left"
                        color={Colors.red}
                        size={24}
                    />
                </TouchableOpacity>
                <Image
                    source={colors[colorIndex] ? { uri: `${BaseURL}/${colors[colorIndex].file}` } : NoImage}
                    style={{ resizeMode: 'contain', minHeight: 280, height: '100%', flex: 1, }}
                />
                <TouchableOpacity onPress={() => switchColor()} style={styles.buttonArrow}>
                    <FontAwesomeIcon
                        name="chevron-right"
                        color={Colors.red}
                        size={24}
                    />
                </TouchableOpacity>
            </View>
            <View style={{
                width: '100%',
                marginTop: 10,
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                <Text style={[styles.baseText, { fontSize: 24, fontWeight: '500', marginTop: 10, maxWidth: 475 }]}>
                    {motor ? motor.name : ''}
                </Text>
                <Text style={[styles.baseText, { fontSize: 16, fontWeight: '600', marginTop: 10, maxWidth: 475 }]}>
                    Harga : {motor ? formatMoney(motor.price) : ''}
                </Text>
                <View
                    style={{
                        flexDirection: 'row',
                        width: '100%',
                        justifyContent: 'center',
                        marginTop: 10,
                        maxWidth: 475
                    }}>
                    <TouchableOpacity onPress={() => downloadImage()} style={styles.buttonSmall}>
                        <FontAwesomeIcon
                            name="download"
                            color={Colors.red}
                            size={24}
                        />
                        <Text style={[styles.baseText, { fontSize: 10 }]}>
                            Download
                        </Text>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => onShare()} style={styles.buttonSmall} disabled>
                        <FontAwesomeIcon
                            name="share"
                            color={Colors.gray100}
                            size={24}
                        />
                        <Text style={[styles.baseText, { fontSize: 10, color: Colors.gray100 }]}>
                            Share
                        </Text>
                    </TouchableOpacity>
                </View>
                <DetailMenu
                    motor={motor}
                    image={colors[0] ? colors[0].file : undefined}
                    onChangeTab={value => setSelectedTab(tabs[value])}
                />
                {currentTab()}
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    buttonArrow: {
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 10
    },
    baseText: {
        paddingHorizontal: 10,
        textAlign: 'center',
        textAlignVertical: 'center'
    },
    buttonSmall: {
        fontSize: 10,
        fontWeight: '600',
        color: Colors.white,
        marginVertical: 5,
        marginHorizontal: 10,
        alignItems: 'center'
    }
});