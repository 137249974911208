import React from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Booking, Detail, Catalog, Dashboard } from "../pages";

export default () => {
    return (
        <Router>
            <Routes>
                <Route path='/' element={<Dashboard/>}>
                    <Route index element={<Catalog/>}/>
                    <Route exact path="/motor/:id" element={<Detail/>}/>
                    <Route exact path="/booking" element={<Booking/>}/>
                </Route>
            </Routes>
        </Router>
    )
}