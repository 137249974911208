import React from "react";
import { ScrollView, View, Dimensions, StyleSheet } from "react-native-web";
import { Outlet } from "react-router-dom";
import Navbar from "./component/Navbar";

const screen = {
    height: Dimensions.get('window').height,
    width: Dimensions.get('window').width,
}

export default () => {
    return (
        <View style={styles.container}>
            <Navbar/>
            <ScrollView contentContainerStyle={styles.content}>
                <Outlet/>
            </ScrollView>
        </View>
    )
}


const styles = StyleSheet.create({
    container: {
        maxHeight: screen.height
    },
    content: {
        alignItems: 'center'
    }
});