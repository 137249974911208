import React from "react";
import { Text } from "react-native-paper";
import { Image, StyleSheet, View } from "react-native";
import { BaseURL } from "../../utils/Constants";
import { DisplayText } from "../../components";
import { Colors } from "../../styles";

export default (props) => {
    const { motor } = props
    const [features, setFeatures] = React.useState([])
    const fetchFeatures = () => {
        fetch(`${BaseURL}/motors/${motor.id}/features`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            const responseJSON = await response.json();
            if (!responseJSON.success) return;
            setFeatures(responseJSON.features);
        }).catch(error => {
            console.log(error);
        });
    }

    React.useEffect(() => {
        fetchFeatures();
    }, [])

    return (
        <View style={{ width: '100%', paddingBottom: 20 }}>
            <Text style={{
                fontSize: 20,
                fontWeight: 'bold',
                width: '100%',
                marginTop: 20,
                marginBottom: 10,
                paddingHorizontal: 10
            }}>
                Fitur Utama
            </Text>
            <View style={{ width: '100%', marginTop: 20 }}>
                {features.map((feature, index) => (
                    <View key={index} style={{ marginVertical: 1 }}>
                        <View style={styles.headerButton}>
                            <Text style={styles.headerText}>{feature.header}</Text>
                        </View>
                        <View style={{ padding: 10, flexDirection: 'row', alignItems: 'center' }}>
                            {!feature.file ? <View/> :
                                <Image
                                    source={{ uri: `${BaseURL}/${feature.file}` }}
                                    style={{ resizeMode: 'contain', height: 64, width: 64 }}
                                />
                            }
                            <DisplayText
                                inline
                                label={feature.detail}
                                style={{ marginBottom: 10 }}
                            />
                        </View>
                    </View>
                ))}
            </View>
        </View>
    )
}


const styles = StyleSheet.create({
    headerButton: {
        borderRadius: 0,
        backgroundColor: Colors.red,
        width: '100%',
        padding: 10
    },
    headerLabel: {
        width: '100%',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    headerText: {
        fontSize: 14,
        fontWeight: '600',
        color: Colors.white,
        textAlignVertical: 'center'
    },
});