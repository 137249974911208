import React from "react";
import { Appbar, DataTable, Text } from "react-native-paper";
import { Image, ScrollView, StyleSheet, TouchableOpacity, View } from "react-native";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import DetailMenu from "../component/DetailMenu";
import DetailHeader from "../component/DetailHeader";
import { BaseURL, setMediaQuery } from "../../utils/Constants";
import { Dashboard } from "../../components";
import { formatMoneyShort } from "../../utils/Helpers";
import { Colors, Typography } from "../../styles";

export default (props) => {
    const { motor } = props
    const mediaQuery = setMediaQuery();
    const [headers, setHeaders] = React.useState([{ dp: 0 }]);
    const [detailLabels, setDetailLabels] = React.useState([]);
    const [detailValues, setDetailValues] = React.useState([[]]);
    const [rightPos, setRightPos] = React.useState(null);
    const [leftPos, setLeftPos] = React.useState(null);
    let throttleScroll = 8;
    let kiri = false, kanan = false;
    const fetchCredit = () => {
        fetch(`${BaseURL}/motors/${motor.id}/credit`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            const responseJSON = await response.json();
            if (!responseJSON.success) return;
            if (responseJSON.data !== "") {
                let dp = responseJSON.data.map(function (x) {
                    return {
                        dp: x.down_payment
                    }
                })
                let header = responseJSON.label.map(function (x) {
                    return x.toString()
                });
                let detailvalue = responseJSON.detail;
                setDetailLabels(header);
                setDetailValues(detailvalue)
                setHeaders(dp);
            }
        }).catch(error => {
            console.log(error);
        });
    }
    React.useEffect(() => {
        fetchCredit();
    }, [])

    return (
        <View style={{ width: '100%', paddingBottom: 20, justifyContent: 'center' }}>
            <View style={{
                flexDirection: 'row',
                alignItems: 'baseline',
                justifyContent: 'space-between',
                marginVertical: 10
            }}>
                <Text style={{ fontSize: 20, fontWeight: 'bold', paddingHorizontal: 10 }}>
                    Pricelist
                </Text>
                <Text style={{ fontSize: 12, textAlign: 'right', paddingHorizontal: 10 }}>* Satuan dalam ribuan</Text>
            </View>
            <View style={[table.rowContainer, { maxHeight: 640, color: Colors.black }]}>
                <DataTable style={{ flex: 0.5, maxWidth: 200 }}>
                    <DataTable.Header style={{ backgroundColor: Colors.red }}>
                        <DataTable.Title>
                            <Text style={Typography.tableTitle}>Uang Muka</Text>
                        </DataTable.Title>
                    </DataTable.Header>
                    <ScrollView
                        scrollEventThrottle={throttleScroll}
                        showsVerticalScrollIndicator={false}
                        nestedScrollEnabled={true}
                        onScroll={e => {
                            if (!kiri) {
                                let scrollY = e.nativeEvent.contentOffset.y;
                                kanan = true
                                rightPos.scrollTo({ y: scrollY })
                            }
                            kiri = false;
                        }}
                        ref={scrollView => {
                            setLeftPos(scrollView)
                        }}
                        contentContainerStyle={{ paddingBottom: 0 }}>
                        {headers.map((item, i) => {
                            return (
                                <DataTable.Row key={i}>
                                    <DataTable.Cell>{formatMoneyShort(item.dp)}</DataTable.Cell>
                                </DataTable.Row>
                            )
                        })}
                    </ScrollView>
                </DataTable>
                <View style={{ height: '100%', width: 1, backgroundColor: Colors.gray100 }}/>
                <ScrollView horizontal style={{ flex: 1 }}>
                    <DataTable style={{ flex: 1, minWidth: mediaQuery.mobileL ? 640 : 800 }}>
                        <DataTable.Header style={{ backgroundColor: Colors.red }}>
                            {detailLabels.map((item, i) => (
                                <DataTable.Title key={i} style={styles.cell}>
                                    <Text style={Typography.tableTitle}>{item} x</Text>
                                </DataTable.Title>
                            ))}
                        </DataTable.Header>
                        <ScrollView
                            scrollEventThrottle={throttleScroll}
                            showsVerticalScrollIndicator={false}
                            nestedScrollEnabled={true}
                            onScroll={e => {
                                // handleScroll
                                if (!kanan) {
                                    let scrollY = e.nativeEvent.contentOffset.y;
                                    kiri = true;
                                    leftPos.scrollTo({ y: scrollY })
                                }
                                kanan = false;
                            }}
                            ref={scrollView => {
                                setRightPos(scrollView)
                            }}>
                            {detailValues.map((cols, i) => (
                                <DataTable.Row key={i}>{cols.map((item, j) => (
                                    <DataTable.Cell key={j} style={styles.cell}>
                                        {formatMoneyShort(item)}
                                    </DataTable.Cell>
                                ))}
                                </DataTable.Row>
                            ))}
                        </ScrollView>
                    </DataTable>
                </ScrollView>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    leftColumn: {
        position: 'absolute',
        height: '100%'
    },
    cell: {
        paddingHorizontal: 10
    },
    scrollView: {
        position: 'relative'
    }
});


const table = StyleSheet.create({
    title: {
        flexDirection: 'row-reverse',
        justifyContent: 'flex-end'
    },
    rowContainer: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 10
    },
});
