import React from "react";
import { Text } from "react-native-paper";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import FontAwesome from "react-native-vector-icons/FontAwesome";
import DetailMenu from "../component/DetailMenu";
import DetailHeader from "../component/DetailHeader";
import {BaseURL} from "../../utils/Constants";
import {Dashboard, DisplayText} from "../../components";
import {Colors} from "../../styles";

// https://stackoverflow.com/questions/1960473/get-all-unique-values-in-a-javascript-array-remove-duplicates
function onlyUnique(value, index, self) {
    return self.indexOf(value) == index;
}

export default (props) => {
    const { motor } = props;
    const [specs, setSpecs] = React.useState([]);
    const [listSpecs, setListSpecs] = React.useState([]);
    const [headers, setHeaders] = React.useState([]);
    const [selectedHeader, setSelectedHeader] = React.useState(undefined);
    const fetchSpecs = () => {
        fetch(`${BaseURL}/motors/${motor.id}/specifications`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            const responseJSON = await response.json();
            if (!responseJSON.success) return;
            let header = responseJSON.specs.map(item => item.header)
            const unique = [...new Set(header)];
            setHeaders(unique.map(item => ({ value: item, label: item })));
            setSpecs(responseJSON.specs);
            setSelectedHeader(header[0])
        }).catch(error => {
            console.log(error);
        });
    }
    const selectHeader = (header) => {
        if (header === selectedHeader) {
            setSelectedHeader(undefined);
        } else {
            setSelectedHeader(header);
        }
    }

    React.useEffect(() => {
        fetchSpecs();
    }, [])

    React.useEffect(() => {
        setListSpecs(specs.filter(spec => selectedHeader && spec.header === selectedHeader.value));
    }, [selectedHeader, specs])

    return (
        <View style={{ width: '100%', paddingBottom: 20 }}>
            <Text style={{
                fontSize: 20,
                fontWeight: 'bold',
                width: '100%',
                marginTop: 20,
                marginBottom: 10,
                paddingHorizontal: 10
            }}>
                Spesifikasi
            </Text>
            <View style={{ width: '100%',  marginTop: 20 }}>
                {headers.map((header, index) => (
                    <View key={index} style={{ marginVertical: 1 }}>
                        <TouchableOpacity
                            onPress={() => selectHeader(header)}
                            style={styles.headerButton}
                        >
                            <View style={styles.headerLabel}>
                                <Text style={styles.headerText}>{header.label}</Text>
                                <FontAwesome
                                    name={selectedHeader === header ? "caret-up" : "caret-down"}
                                    color={Colors.white}
                                    size={20}
                                />
                            </View>
                        </TouchableOpacity>
                        {selectedHeader === header ? (
                            <View style={{ marginTop: 10 }}>
                                {listSpecs.map((item, index) =>
                                    <DisplayText
                                        key={index}
                                        inline
                                        title={item ? item.name : ''}
                                        label={item ? item.detail : ''}
                                        style={{ marginBottom: 10, marginHorizontal: 5 }}
                                    />
                                )}
                            </View>
                        ) : <></>}
                    </View>
                ))}
            </View>
        </View>
    )
}


const styles = StyleSheet.create({
    headerButton: {
        borderRadius: 0,
        backgroundColor: Colors.red,
        width: '100%',
        padding: 10
    },
    headerLabel: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    headerText: {
        fontSize: 14,
        fontWeight: '600',
        color: Colors.white,
        textAlignVertical: 'center'
    }
});