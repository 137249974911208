import React from "react";
import { Image, StyleSheet, View } from "react-native-web";
import { Link, useNavigate } from "react-router-dom";
import { Logo, LogoSmall } from "../../images";
import { setMediaQuery } from "../../utils/Constants";
import { Button } from "react-native-paper";
import { Colors } from "../../styles";

export default () => {
    const navigate = useNavigate();
    const mediaQuery = setMediaQuery();
    const logo = mediaQuery.desktop ? Logo : LogoSmall;
    return (
        <View style={styles.header}>
            <Image style={styles.logo} source={logo}/>
            <View style={styles.navbar}>
                <Link to={'/'} style={{ textDecorationLine: 'none' }}>
                    <Button
                        mode="text"
                        compact
                        // onPress={() => navigate('/')}
                        color={Colors.white}
                        style={{ marginRight: 10 }}
                        labelStyle={{ fontWeight: 600, fontSize: 14 }}
                        uppercase={false}>
                        Catalog
                    </Button>
                </Link>
                <Link to={'/booking'} style={{ textDecorationLine: 'none' }}>
                    <Button
                        mode="text"
                        compact
                        // onPress={() => navigate('/booking')}
                        color={Colors.white}
                        style={{ marginRight: 10 }}
                        labelStyle={{ fontWeight: 600, fontSize: 14 }}
                        uppercase={false}>
                        Booking
                    </Button>
                </Link>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    header: {
        backgroundColor: Colors.red,
        width: '100%',
        height: 42,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingHorizontal: 10,
        paddingVertical: 5
    },
    navbar: {
        width: '80%',
        height: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'end'
    },
    logo: {
        width: '20%',
        height: '100%',
        resizeMode: 'contain'
    },
});