import { Dimensions, StyleSheet } from "react-native";
import Colors from "./Colors";

export const dimensions = {
    height: Dimensions.get('window').height,
    width: Dimensions.get('window').width,
};
export const Layout = StyleSheet.create({
    body: {
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center'
    },
    content: {
        flex: 1,
        paddingHorizontal: 10,
        width: dimensions.width < 800 ? '100%' : '80%',
    },
    overlay: {
        backgroundColor: Colors.gray100,
        opacity: 0.5,
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        justifyContent: "center"
    },
    logo: {
        width: 200,
        height: '100%',
        resizeMode: 'contain'
    },
    grid: {
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    cardContainer: {
        flexBasis: '50%',
        padding: 10
    },
    divider: {
        width: '100%',
        height: 1,
        color: Colors.gray,
        marginBottom: 16
    },
    img: {
        resizeMode: 'contain',
        flex: 1,
        minHeight: 200,
        minWidth: 400
    },

    leftTable: {
        flex: dimensions.width < 640 ? 0.5 : 0.25
    },
    tableHeader: {
        backgroundColor: Colors.red
    },
    tableCell: {
        width: 160,
        padding: 0,
        margin: 0
    },
    tableCellDisabled: {
        backgroundColor: Colors.gray
    },

    input: {
        borderColor: Colors.gray,
        borderWidth: 1,
        borderRadius: 5,
        paddingHorizontal: 20,
        paddingVertical: 10,
    },
});

export const Typography = StyleSheet.create({
    h1: {
        fontSize: dimensions.width < 640 ? 20 : 24,
        fontWeight: 'bold',
        margin: 8,
        marginLeft: 0
    },
    h2: {
        fontSize: dimensions.width < 640 ? 16 : 20,
        fontWeight: '500',
        margin: 8,
        marginLeft: 0
    },
    h3: {
        fontSize: dimensions.width < 640 ? 14 : 16,
        margin: 8,
        marginLeft: 0
    },
    tableTitle: {
        color: Colors.white,
        fontSize: 14,
        marginRight: 10
    },
});