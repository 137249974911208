import React from 'react';
import { useNavigate } from "react-router";
import { ActivityIndicator, Button, Card, IconButton, Text } from "react-native-paper";
import { SectionGrid } from "react-native-super-grid";
import { Image, View } from "react-native-web";
import { BaseURL, setMediaQuery } from "../utils/Constants";
import { formatMoney } from "../utils/Helpers";
import { NoImage } from "../images";
import { SearchBar, ToggleButton } from "../components";
import { Colors, Typography } from "../styles";
import { StyleSheet } from "react-native";
import { Link } from "react-router-dom";

const ranges = ['Low', 'Mid', 'High'];
export default function Catalog() {
    const navigate = useNavigate();
    const mediaQuery = setMediaQuery();
    const [isLoading, setIsLoading] = React.useState(false);
    const [showSearch, setShowSearch] = React.useState(false);
    const [searchType, setSearchType] = React.useState(1);
    const [searchQuery, setSearchQuery] = React.useState('');
    const [types, setTypes] = React.useState([]);
    const [motors, setMotors] = React.useState([]);
    const [listMotors, setListMotors] = React.useState([]);
    const [priceRange, setPriceRange] = React.useState([]);
    const [selectedRange, setSelectedRange] = React.useState(0);

    const fetchTypes = () => {
        fetch(`${BaseURL}/motors/types`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            const responseJSON = await response.json();
            if (!responseJSON.success) return;
            const mappedData = []
            responseJSON.data.map(item => mappedData.push({ value: item.id, label: item.name }));
            setTypes(mappedData);
        }).finally(() => {
            setIsLoading(false);
        });
    }
    const fetchMotors = () => {
        fetch(`${BaseURL}/motors`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            const responseJSON = await response.json();
            if (responseJSON.success) {
                const data = responseJSON.data.filter(item => {
                    return item.status === 1
                });
                setMotors(data);
            }
        }).finally(() => {
            setIsLoading(false);
        });
    }
    const fetchPriceRange = () => {
        fetch(`${BaseURL}/motors/${searchType}/priceRange`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            const responseJSON = await response.json();
            if (!responseJSON.success) return;
            setPriceRange(responseJSON.data);
            setSelectedRange(responseJSON.data[0]);
        }).catch(error => {
            console.warn(error);
        }).finally(() => {
            setIsLoading(false);
        });
    }
    const filterData = () => {
        const data = motors.filter(motor => (
            motor.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
            motor.range === selectedRange &&
            motor.type_id === searchType
        ));
        const families = data
            .map(motor => motor.family)
            .filter((value, index, self) => (
                self.indexOf(value) === index
            ));
        const result = [];
        for (const family of families) {
            const items = data.filter(item => item.family === family);
            result.push({ title: family.toUpperCase(), data: items });
        }
        setListMotors(result);
    };

    React.useEffect(() => {
        filterData();
    }, [searchQuery, selectedRange, motors, searchType]);

    React.useEffect(() => {
        fetchTypes();
        fetchMotors();
        // fetchPriceRange();
    }, []);


    const card = (item) => {
        const motor = item.item;
        const onPress = () => {
            navigate(`/motor/${motor.id}`);
        }
        return (
            <Link to={`/motor/${motor.id}`} style={{ textDecorationLine: 'none' }}>
                <Card style={styles.unitCard}>
                    <Card.Content style={{ paddingTop: 0 }}>
                        <Image style={{ flex: 1, minHeight: 200, resizeMode: 'contain' }}
                               source={motor.img ? { uri: `${BaseURL}/${motor.img}` } : NoImage}/>
                    </Card.Content>
                    <Card.Title title={motor.name} subtitle={motor.type} titleStyle={styles.cardTitle}
                                titleNumberOfLines={2}/>
                    <Card.Content>
                        <Text style={{ fontSize: 10, marginBottom: 4 }}>OTR</Text>
                        <Text style={{ fontSize: 12, fontWeight: 'bold' }}>{formatMoney(motor.price)}</Text>
                    </Card.Content>
                </Card>
            </Link>
        )
    }

    if (isLoading) return (
        <ActivityIndicator animating={true} color={Colors.red} size={'large'}/>
    )
    return (
        <View style={{ width: mediaQuery.tablet ? '100%' : '80%' }}>
            <Text style={styles.title}>
                Pilih Motor Favorit Anda
            </Text>
            <View style={styles.header}>
                <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'end' }}>
                    {types.map((item, index) => (
                        <ToggleButton
                            key={index}
                            label={item.label}
                            status={item.value === searchType}
                            onPress={() => setSearchType(item.value)}
                        />
                    ))}
                </View>
                <IconButton
                    icon="magnify"
                    color={Colors.red}
                    size={mediaQuery.desktop ? 24 : 20}
                    onPress={() => setShowSearch(!showSearch)}
                    style={{ marginVertical: 0 }}
                />
            </View>
            <View style={{ flexDirection: 'row', justifyContent: 'flex-end', marginVertical: 5 }}>
                {ranges.map((range, index) => (
                    <Button
                        key={index} mode="contained"
                        color={Colors.white}
                        onPress={() => setSelectedRange(index)}
                        style={styles.rangeButton}
                        labelStyle={styles.rangeButtonLabel}>
                        {range}
                    </Button>
                ))}
            </View>
            {!showSearch ? <View/> :
                <SearchBar
                    placeholder="Cari Motor"
                    value={searchQuery}
                    onChangeText={setSearchQuery}
                    containerStyle={{ marginHorizontal: 5 }}
                />
            }
            <SectionGrid
                itemDimension={mediaQuery.desktop ? 180 : 120}
                sections={listMotors}
                keyExtractor={item => item.id}
                renderItem={card}
                renderSectionHeader={({ section: { title } }) => (
                    <Text style={styles.section}>{title}</Text>
                )}
                spacing={5}
            />
        </View>
    );
}
const styles = StyleSheet.create({
    title: {
        fontSize: 18, fontWeight: '600',
        marginHorizontal: 5, marginTop: 15, marginBottom: 10
    },
    section: {
        textAlign: 'center',
        fontSize: 16,
        fontWeight: '700',
        marginTop: 8,
        marginBottom : 4,
        paddingVertical: 5,
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
        backgroundColor: Colors.red,
        color: Colors.white
    },
    gridView: {
        width: '80%'
    },
    header: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    unitCard: {
        flex: 1
    },
    cardTitle: {
        fontSize: 18,
        fontWeight: "500"
    },
    rangeButton: {
        backgroundColor: Colors.red,
        borderRadius: 10,
        marginHorizontal: 2,
    },
    rangeButtonLabel: {
        color: Colors.white,
        fontSize: 10,
        fontWeight: '500',
        textAlignVertical: 'bottom',
        marginVertical: 6,
        marginHorizontal: 0,
    }
});
