// export const BaseURL = "https://astra.bruhcompany.xyz/api";
// export const BaseURL = "http://localhost:8000/api";
import { useMediaQuery } from "react-responsive";
export const BaseURL = "https://api.hondabengkulu.co.id/api";
// export const BaseURL = "http://localhost:8000/api";
export const setMediaQuery = () => ({
    mobile: useMediaQuery({ minWidth: 480, maxWidth: 640 }),
    desktop: useMediaQuery({ minWidth: 640 }),
    mobileS: useMediaQuery({ maxWidth: 321 }),
    mobileM: useMediaQuery({ maxWidth: 376 }),
    mobileL: useMediaQuery({ maxWidth: 481 }),
    tablet: useMediaQuery({ maxWidth: 769 }),
    sm: useMediaQuery({ minWidth: 800 }),
    md: useMediaQuery({ minWidth: 920 }),
    xl: useMediaQuery({ minWidth: 1280 })
});
