import { DefaultTheme, Provider as PaperProvider } from 'react-native-paper';
import { default as Router } from './src/router'

export default function App() {
    return (
        <PaperProvider theme={Theme}>
            <Router/>
        </PaperProvider>
    );
}

const Theme = {
    ...DefaultTheme,
    roundness: 2,
    colors: {
        ...DefaultTheme.colors
    },
};
