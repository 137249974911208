import moment from 'moment';
import numeral from 'numeral';

export const formatDateShort = (date) => {
    return date ? moment(date).format('DD MMM YYYY') : '-';
};
export const formatDateLong = (date) => {
    const momentLocale = moment(date).locale('id');
    return momentLocale.format('DD MMMM YYYY');
};
export const formatMoney = (number = 0) => {
    return number && !isNaN(number) ? `Rp ${numeral(number).format('0,0')}` :  "-";
};
export const formatMoneyShort = (number = 0) => {
    return number && !isNaN(number) ? `Rp ${numeral(number).format('0,0')}` :  "-";
};
