import { Image, StyleSheet, TouchableOpacity, View } from "react-native";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import { Text } from "react-native-paper";
import {Colors} from "../../styles";
import {Icon} from "../../images";

export default (props) => {
    const { motor, image, onChangeTab} = props
    if (!motor) return <></>;
    return (
        <View style={styles.menuRowContainer}>
            <View style={styles.menuButtonContainer}>
                <TouchableOpacity
                    onPress={() => {
                        onChangeTab('feature');
                        // navigation.navigate('Feature', {
                        //     data: motor,
                        //     image: image
                        // })
                    }}
                    style={styles.buttonCircle}
                >
                    <MaterialCommunityIcons
                        name="star"
                        color={Colors.white}
                        size={22}
                    />
                </TouchableOpacity>
                <Text style={[styles.baseText, { fontSize: 9, color: Colors.black, marginTop: 5 }]}>
                    FITUR UTAMA
                </Text>
            </View>
            <View style={styles.menuButtonContainer}>
                <TouchableOpacity
                    onPress={() => {
                        onChangeTab('specs');
                        // navigation.navigate('Specs', {
                        //     data: motor,
                        //     image: image
                        // })
                    }}
                    style={styles.buttonCircle}
                >
                    <MaterialCommunityIcons
                        name="engine"
                        color={Colors.white}
                        size={20}
                    />
                </TouchableOpacity>
                <Text style={[styles.baseText, { fontSize: 9, color: Colors.black, marginTop: 5 }]}>
                    SPESIFIKASI
                </Text>
            </View>
            <View style={styles.menuButtonContainer}>
                <TouchableOpacity
                    onPress={() => {
                        onChangeTab('pricelist')
                        // navigation.navigate('Pricelist', {
                        //     data: motor,
                        //     image: image
                        // })
                    }}
                    style={styles.buttonCircle}
                >
                    <Image
                        source={Icon.form}
                        style={{
                            width: 16,
                            height: 16,
                            marginLeft: 4,
                            tintColor: Colors.white
                        }}
                    />
                </TouchableOpacity>
                <Text style={[styles.baseText, { fontSize: 9, color: Colors.black, marginTop: 5 }]}>
                    PRICELIST
                </Text>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    buttonCircle: {
        width: 48,
        height: 48,
        borderRadius: 24,
        borderWidth: 3,
        borderColor: Colors.gray100,
        backgroundColor: Colors.red,
        justifyContent: 'center',
        alignItems: 'center'
    },
    menuRowContainer: {
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 20,
        marginBottom: 20,
        maxWidth: 475
    },
    menuButtonContainer: {
        justifyContent: 'center',
        alignItems: 'center',
        width: `${100 / 3 - 5}%`
    }
});